<template>
  <div>
    <v-sheet>
      <v-container py-12>
        <v-row>
          <v-col>
            <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">
              DevOps Consulting Services
            </h2>
            <h3 class="text-h4 text-lg-h3 mt-0 mt-xl-10">
              Application Delivery and Operational Efficiency at High Velocity
            </h3>
            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              Fast-paced development and quicker time-to-market are the two key
              factors deciding the success of any firm in today’s IT industry.
            </p>
            <p>
              Besides, there are some other challenges, listed below, that
              majority of firms are facing today:
            </p>
            <ul>
              <li>
                Lack of cultural balance and communication between software
                Development and Operations
              </li>
              <li>
                Lack of agile delivery to drive software-driven innovation
              </li>
              <li>
                Continued existence of silos between Development and Operations
                teams
              </li>
              <li>
                Non-involvement of DBAs in release cycles, significantly
                hampering the inner circles
              </li>
              <li>
                Lack of acceptance on a holistic view of the entire value-chain
                for continuous software delivery
              </li>
            </ul>
            <p>
              DevOps has an answer for many such concerns! DevOps as a Service
              recommends a new ‘culture’ that ideally bridges gaps between the
              core operating teams i.e., Development (Dev) and Operations (Ops),
              for increased collaboration and enhanced productivity.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              DPI DevOps Services
            </h4>
            <p>
              DPI, with over a decade of proven excellence in IT, has a team
              of experts who can provide you an effective DevOps implementation
              strategy.
            </p>

            <p>
              We provide customers with services encompassing several
              capabilities such as iterative and incremental development,
              workload management on-demand, lightweight architecture, security
              and automated testing techniques.
            </p>
            <h4 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              Our Portfolio of DevOps Services
            </h4>
            <p>
              A successful DevOps model is based on four key building blocks
              Develop, Build and Test, Deploy, Operate and Monitor. DPI can
              do it for you!
            </p>
          </v-col>
          <v-col v-for="item of devOpsServices" :key="item.autoId" cols="4">
            <v-card flat height="100%">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col class="display-3" cols="auto">
                    <v-avatar color="link" size="120" tile>
                      <v-avatar color="paper" size="70" tile>
                        <v-icon size="48" color="link">{{ item.icon }}</v-icon>
                      </v-avatar>
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <div
                      class="text-h5 font-weight-bold link--text text--darken-1"
                    >
                      {{ item.title }}
                    </div>
                    <div class="body-1 link--text text--darken-3 py-2">
                      {{ item.desc }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="link darken-2" dark>
      <v-container py-12>
        <v-row>
          <v-col cols="12">
            <h2 class="text-h4">
              Additionally, we offer a range of DevOps Managed Services to
              simplify your DevOps transformation journey.
            </h2>
            <p class="text-h6">
              Our Managed Service Providers (MSPs) assist you in:
            </p>
          </v-col>
          <v-col
            lg="4"
            md="6"
            sm="12"
            v-for="item of devOpsManagedService"
            :key="item.autoId"
          >
            <v-alert
              border="bottom"
              colored-border
              color="link lighten-1"
              elevation="8"
              light
            >
              <div class="title">
                {{ item.title }}
              </div>
              {{ item.desc }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet>
      <v-container py-12>
        <v-row justify="center">
          <v-col lg="8" md="10" sm="12">
            <h2 class="text-h5">
              Additionally, we offer a range of DevOps Managed Services to
              simplify your DevOps transformation journey.
            </h2>
            <p class="body-2">
              Our Managed Service Providers (MSPs) assist you in:
            </p>
          </v-col>
          <v-col lg="9" md="10" sm="12">
            <v-img src="@/assets/img-devops-tools-services.png"></v-img>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12">
            <h2 class="text-h5">
              The Value We Add
            </h2>
            <p class="body-2">
              Our DevOps services and solutions provide several value-added
              benefits such as:
            </p>
          </v-col>
          <v-col cols="6">
            <ul>
              <li>Custom-developed monitoring solution</li>
              <li>Automated infrastructure provisioning</li>
              <li>Resiliency</li>
              <li>Increased agility</li>
            </ul>
          </v-col>
          <v-col cols="6">
            <ul>
              <li>Enhanced quality</li>
              <li>Improved innovation</li>
              <li>Reduced outages</li>
              <li>Scalability</li>
            </ul>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <h2 class="text-h5">
              Data Insights: DevOps Data Insights and Business Impact
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card height="100%" color="green" dark>
              <v-card-title
                >Global DevOps Market Forecast (2017-23)</v-card-title
              >
              <v-card-text>
                <ul class="body-1 font-weight-black">
                  <li>USD 9.41 billion by 2023</li>
                  <li>18.7% CAGR</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-for="item of devOpsData" :key="item.autoId">
            <v-card height="100%" :color="item.color" dark>
              <v-card-title class="text-h4">
                {{ item.stat }}
              </v-card-title>
              <v-card-text>
                <p class="body-1 font-weight-black">{{ item.desc }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Cloud",
  components: {},
  data() {
    return {
      devOpsServices: [
        {
          autoId: 1,
          title: "Assessment and Planning",
          icon: "mdi-notebook",
          desc:
            "We create a DevOps Assessment roadmap by visualizing the desired state and identifying the traceable metrics",
        },
        {
          autoId: 2,
          title: "Pilot Framework Creation",
          icon: "mdi-database-cog",
          desc:
            "We leverage and integrate your existing tools with our robust ecosystem of open source and licensed tools",
        },
        {
          autoId: 3,
          title: "Process Implementation",
          icon: "mdi-message-processing",
          desc:
            "We proceed with the analysis, design, construction, automation and implementation in the identified areas",
        },
        {
          autoId: 4,
          title: "CI/CD Pipeline",
          icon: "mdi-infinity",
          desc:
            "We bridge the Dev-Ops gap through continuous development, continuous integration, continuous testing and continuous deployment",
        },
        {
          autoId: 5,
          title: "Process Automation",
          icon: "mdi-robot",
          desc:
            "We automate the entire process pipeline from code generation to production, covering builds, test cases, quality checks, security and more",
        },
        {
          autoId: 5,
          title: "Security Integration",
          icon: "mdi-security",
          desc:
            "We perform end-to-end security integration through ‘Security as Code’ mechanism using DevSecOps",
        },
      ],
      devOpsManagedService: [
        {
          autoId: 1,
          title: "Operational Management",
          desc:
            "Cloud infrastructure, hardware, network, security and data easing operational burden",
        },
        {
          autoId: 2,
          title: "Add-on Services",
          desc:
            "Application maintenance and development that boosts the pace of your DevOps journey",
        },
        {
          autoId: 3,
          title: "Steady Pace",
          desc:
            "High technical expertise that brings a steady pace in overall DevOps functioning",
        },
        {
          autoId: 4,
          title: "Enhanced Flow",
          desc:
            "Toolchains, containers and processes powered by site reliability and continuous pipeline",
        },
        {
          autoId: 5,
          title: "Effective Supply-chain",
          desc:
            "For mature processes and platforms, addressing constraints and effective supply-chain performance",
        },
        {
          autoId: 6,
          title: "Regular Monitoring",
          desc:
            "Reviews of internal teams, platforms and pipeline performance KPIs to ensure no gaps",
        },
        {
          autoId: 7,
          title: "Enhanced Collaboration",
          desc:
            "Streamlined team communication through efficient use of relevant tools",
        },
        {
          autoId: 8,
          title: "Release Management",
          desc:
            "Minimized failed deployment risks through effective release management",
        },
        {
          autoId: 9,
          title: "Security Management",
          desc:
            "Comprehensive protection from potential threats through security measures",
        },
      ],
      devOpsData: [
        {
          autoId: 1,
          stat: "50%",
          desc: "Of organizations have already implemented DevOps as of 2018",
          color: "blue",
        },
        {
          autoId: 2,
          stat: "42%",
          desc:
            "Strong DevOps culture across multiple teams within a department",
          color: "red",
        },
        {
          autoId: 3,
          stat: "22%",
          desc:
            "Minimized time on unplanned work and rework with DevOps practices",
          color: "purple",
        },
        {
          autoId: 4,
          stat: "200X",
          desc:
            "Deployment frequency of firms with DevOps compared to those without",
          color: "teal",
        },
        {
          autoId: 5,
          stat: "20%",
          desc: "Expected CAGR of DevOps Platform market during 2018-22",
          color: "orange",
        },
        {
          autoId: 6,
          stat: "440X",
          desc: "Faster lead time for changes with DevOps principles",
          color: "pink",
        },
        {
          autoId: 7,
          stat: "24X",
          desc:
            "Frequency of faster recovery from failure and minimized downtime with DevOps",
          color: "yellow darken-2",
        },
      ],
    };
  },
};
</script>
