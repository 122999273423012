<template>
  <div>
    <v-sheet>
      <v-container py-12>
        <v-row>
          <v-col md="6" sm="12">
            <v-img
              height="100%"
              src="@/assets/img-about.jpg"
              class="rounded-xl elevation-10"
            />
          </v-col>
          <v-col md="6" sm="12">
            <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">
              Company Overview
            </h2>
            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              DPI is a global IT consulting services provider based out of
              Texas, United States. We focus on delivering high value to clients
              through integrated, reliable, responsive and cost-effective
              solutions. We have been a trusted partner to several small, medium
              and large companies including Fortune 500 firms for over a decade,
              enabling businesses to overcome critical business challenges.
            </p>

            <p>
              We hold profound experience and expertise in providing solutions
              for complex IT implementation projects and integrating emerging
              technologies in a dynamic environment.
            </p>
            <p>
              Our cutting-edge services made us a seamless extension of our
              clients. With our dynamic, scalable, resilient and responsive
              offerings, we ensure rapid growth and high value for our clients.
            </p>
            <p>
              DPI consulting services include a wide range of technology
              service offerings such as Cloud, Containerization, DevOps, Digital
              Transformation, Identity and Access Management, IT Infrastructure,
              Managed Services, Technology Advisory and Virtualization Services.
            </p>
            <p>
              We gauge our success by customer success and regard it as a
              privilege to ensure 100% customer satisfaction.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="link lighten-5">
      <v-container py-12>
        <v-row>
          <v-col>
            <v-card
              color="transparent"
              flat
              class="d-flex flex-column text-center"
            >
              <h3 class="text-h3">Methodology</h3>
              <p>
                DPI follows a proprietary delivery methodology ‘Flows’ which
                ensures, the implementation of all required processes and
                quality checks with a high-level focus on metrics in every phase
                of the Software Development Life Cycle.
              </p>
              <v-img
                max-height="600px"
                src="@/assets/img-about-sdlc.png"
                contain
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet>
      <v-container py-12>
        <v-row>
          <v-col md="6" sm="12">
            <h4 class="text-h4 text-lg-h3 mt-0 mt-xl-10">
              Telecom
            </h4>
            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              With the advent of the app economy and rapid innovation in the
              delivery of data and voice, Telecom firms are forced to bring in
              operational efficiency and higher productivity with rapid
              innovation.
            </p>

            <p>
              DPI has been supporting its telecom clients within billing,
              customer engagement, customer analytics, field operations
              optimization, asset management and UX/UI design and development of
              web and mobile portals, among other services.
            </p>
          </v-col>
          <v-col md="6" sm="12">
            <v-img
              height="100%"
              src="@/assets/img-about-telecom.jpg"
              class="rounded-xl elevation-10"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <StrategicAlliances />
    <v-sheet>
      <v-container py-12>
        <v-row>
          <v-col md="4" sm="12">
            <v-img
              max-height="600px"
              src="@/assets/img-about-diversity.jpg"
              class="rounded-xl elevation-10"
              position="bottom center"
            />
          </v-col>
          <v-col md="8" sm="12">
            <h5 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              Culture and Diversity
            </h5>
            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              DPI is a global company with diversity embedded in its
              culture. We bring together team members from a wide variety of
              backgrounds. Our team-oriented environment is innovative and
              fast-paced. At DPI, we value our employees, empower them,
              recognize and reward them for their excellent performance. We
              invest in our employees by providing ample opportunities to learn
              the latest technologies and acquire new skills and industry
              knowledge.
            </p>

            <p>
              DPI is an equal opportunity employer. We provide equal
              employment opportunities in our recruitment and selection
              policies. We also respect differences in perspective as we firmly
              believe that these differences build an innovative and healthy
              work environment. Our policy is to recruit applicants or promote
              all employees without any regard to race, color, religion, age,
              sex, marital status, sexual preference, national origin, physical
              or mental disability, veteran status or any other diverse groups.
              We have an open-door policy to address any concerns of our
              employees. Through our commitment to ethics and diversity in our
              business, we strive to build successful long-term relationships
              with our clients, partners and communities.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="accent" dark>
      <v-container py-12>
        <v-row>
          <v-col md="8" sm="12">
            <h5 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              Core Values
            </h5>
            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              DPI adheres to a set of core values in all business dealings.
              All our employees are expected to take ownership of these values
              and incorporate them into their everyday activities.
            </p>

            <ul>
              <li>Diligence</li>
              <li>Empathy</li>
              <li>Excellence</li>
              <li>Ingenuity</li>
              <li>Verity</li>
            </ul>
          </v-col>
          <v-col md="4" sm="12">
            <v-img
              max-height="300px"
              src="@/assets/img-about-core.jpg"
              class="rounded-xl elevation-10"
              position="bottom center"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="gray">
      <v-container py-12>
        <v-row justify="center">
          <v-col md="8" sm="12">
            <h5 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              Corporate Social Responsibility (CSR)
            </h5>
            <p class="text-body-1 mt-8 mt-xl-10">
              DPI is committed to bringing in a positive and impactful
              change in the communities around the world through social
              responsibility initiatives. We strongly believe that we must to
              contribute to the betterment of our society and this belief has
              led to the formation of the DPI Foundation. We actively
              conduct activities for contributing to the transformation and
              sustainable development of the society. We also encourage all our
              employees to participate in various social responsibility programs
              in their own small ways.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import StrategicAlliances from "@/components/StrategicAlliances.vue";

export default {
  components: {
    StrategicAlliances,
  },
  data() {
    return {};
  },
};
</script>
