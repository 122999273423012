<template>
  <div>
    <v-sheet>
      <v-container py-12>
        <v-row>
          <v-col>
            <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">
              Cloud Consulting Services
            </h2>
            <h3 class="text-h4 text-lg-h3 mt-0 mt-xl-10">
              Manage Your IT Infrastructure with DPI Cloud Services
            </h3>
            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              Cloud Computing Consulting Services
            </p>

            <p>
              DPI helps organizations transform their IT environment with
              best-in-class Cloud Computing Services. Our team of experts
              delivers highly-effective and reliable cloud computing services
              that provide organizations with a competitive edge.
            </p>
            <p>
              Our cloud solutions enable organizations to reduce IT resource
              requirements and improve productivity, in addition to lowering
              costs and reducing the time-to-market.
            </p>
            <p>
              We assist customers with our on-demand delivery of computing
              services, tools and applications such as servers, storage,
              databases, networking, software, apps, among others.
            </p>
            <p>
              By enabling organizations for cloud adoption, we help them become
              more agile and responsive to the changing market landscape, thus
              supporting them in the right decision-making and maximizing their
              Return on Investment (ROI).
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet>
      <v-container py-12>
        <v-row>
          <v-col sm="6">
            <h3 class="text-h4 text-lg-h3 mt-0 mt-xl-10">
              How Do We Help ?
            </h3>

            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              Whether your applications are running in an on-premise captive
              data center, a third-party hosted private or public cloud, or a
              combination of the two, we are a one-stop cloud service provider
              for world-class cloud services with diverse options. Besides, we
              can also seamlessly scale and migrate your chosen application
              workloads.
            </p>

            <p>
              Our cloud consulting framework addresses all customer challenges
              pertaining to a seamless transition to the cloud.
            </p>
          </v-col>
          <v-col sm="6">
            <h3 class="text-h4 text-lg-h3 mt-0 mt-xl-10">
              Our Methodology
            </h3>
            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              We take a strategic approach to implementing cloud consulting
              services. Our methodology involves a four-step process:
            </p>

            <ol>
              <li>
                Assessment – This phase is more of determining use-cases,
                followed by exploring suitable solutions, experimenting the same
                and checking the feasibility. During this process, we evaluate
                your technical compatibilities and business objectives in line
                with the expected ROI.
              </li>

              <li>
                Planning – This is the step to design and planned implementation
                backed by architecture design and clear deployment plan in line
                with the planned objectives. Here, we provide a clear roadmap
                and strategical procedures.
              </li>

              <li>
                Deployment – A step forward from the previous phase of planning
                towards realizing the solution in production. In simple words,
                this phase involves execution, migration and expansion.
              </li>

              <li>
                Optimization – Right after implementation is the crucial stage
                of optimizing what works the best. Here we look for ways to
                introduce operational best practices and opportunities to
                review, improve existing processes.
              </li>
            </ol>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="success" dark>
      <v-container py-12 fluid>
        <v-row>
          <v-col>
            <h4
              class="text-h5 text-lg-h4 mt-0 mt-xl-10 success--text text--lighten-4"
            >
              Our Portfolio of Cloud Computing Services
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item of computerServices"
            :key="item.autoId"
            lg="4"
            md="6"
            sm="12"
          >
            <v-card class="mx-auto" color="success darken-2" height="100%">
              <v-card-title>
                <v-avatar
                  color="success darken-1"
                  size="48"
                  class="elevation-6 mr-4"
                >
                  <span class="white--text headline">
                    {{ item.autoId }}
                  </span>
                </v-avatar>
                <span class="title font-weight-light">{{ item.title }}</span>
              </v-card-title>
              <v-divider />
              <v-card-text class="subtitle-1 font-weight-bold">
                <ul>
                  <li v-for="bullet of item.list" :key="bullet">
                    {{ bullet.bullet }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet>
      <v-container py-12>
        <v-row>
          <v-col>
            <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">
              Why Choose Us
            </h2>

            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              As a US-based cloud consulting firm, DPI provides the entire
              gamut of cloud services encompassing the public, private and
              hybrid cloud types. Based on customer requirements, we utilize the
              Software-as-a-Service (SaaS), Platform-as-a-Service (PaaS) and
              Infrastructure-as-a-Service (IaaS) service models.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="item of whyChooseUs" :key="item.autoId" cols="3">
            <v-card color="link lighten-5 mx-2" height="100%">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col class="display-3" cols="auto">
                    <v-avatar color="link lighten-3" size="120">
                      <v-avatar color="link" size="70">
                        <v-icon size="48" dark>{{ item.icon }}</v-icon>
                      </v-avatar>
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <div
                      class="text-h4 font-weight-bold link--text text--darken-1"
                    >
                      {{ item.title }}
                    </div>
                    <div class="body-1 link--text text--darken-3 py-6">
                      {{ item.desc }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet color="accent" dark>
      <v-container py-12>
        <v-row>
          <v-col>
            <h5 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              What Sets Us Apart?
            </h5>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            sm="12"
            md="6"
            lg="3"
            v-for="item in setsApart"
            :key="item.autoId"
          >
            <v-list color="transparent">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="success">mdi-check-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-h5"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              Data Insights: Cloud Computing Services and Business Impact
            </h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="6" lg="4">
            <v-card height="100%" color="primary">
              <v-card-text class="font-weight-black text-h5">
                Global Cloud Market Forecast (2019-23) USD 623.3 billion by 2023
                18% CAGR
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            sm="12"
            md="6"
            lg="4"
            v-for="item in dtaInsights"
            :key="item.autoId"
          >
            <v-card height="100%" :color="item.color">
              <v-card-text class="font-weight-black text-h5">
                <v-container pa-0>
                  <v-row dense align="center">
                    <v-col cols="auto" class="pr-3">
                      <v-progress-circular
                        rotate="-90"
                        size="100"
                        width="15"
                        :value="item.perc"
                        :color="`${item.color} lighten-5`"
                      >
                        {{ item.perc }}
                      </v-progress-circular>
                    </v-col>
                    <v-col>
                      {{ item.desc }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="text-h4 text-lg-h3 mt-0 mt-xl-10">
              Get Started Now
            </h3>
            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              DPI cloud computing architects, consultants and experts can
              assist your organization in tailoring the perfect cloud solution
              for all your business requirements. You can reach out to us for
              more information on how to avail our enterprise cloud services.
              Contact us @ 972-753-0022 or Email us at info@DPI.com.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Cloud",
  components: {},
  data() {
    return {
      computerServices: [
        {
          autoId: 1,
          title: "Cloud Strategy Services",
          list: [
            {
              bullet: "Cloud readiness assessment",
            },
            {
              bullet: "Cloud architecture audit",
            },
            {
              bullet: "Cloud roadmap and strategy",
            },
            {
              bullet: "Multi-cloud strategy and cloud fitment analysis",
            },
          ],
        },
        {
          autoId: 2,
          title: "Application Development Services",
          list: [
            {
              bullet: "Developing, customizing and migrating applications",
            },
            {
              bullet: "Roadmap for infrastructure management",
            },
          ],
        },
        {
          autoId: 3,
          title: "Maintenance and Security Services",
          list: [
            {
              bullet: "Continuous monitoring",
            },
            {
              bullet: "Performance optimization",
            },
            {
              bullet: "Security and Risk assessment",
            },
            {
              bullet: "Data protection",
            },
          ],
        },
        {
          autoId: 4,
          title: "Cloud Migration Services",
          list: [
            {
              bullet: "SWOT Analysis",
            },
            {
              bullet: "Readiness Assessment",
            },
            {
              bullet: "Cloud-to-cloud movement",
            },
            {
              bullet: "Migration of workloads",
            },
            {
              bullet: "Validation and performance testing",
            },
          ],
        },
        {
          autoId: 5,
          title: "Disaster Recovery Management Services",
          list: [
            {
              bullet: "Application protection",
            },
            {
              bullet: "Remote management of all backups",
            },
            {
              bullet: "Customized disaster recovery plans",
            },
          ],
        },
        {
          autoId: 6,
          title: "Monitoring Services",
          list: [
            {
              bullet: "Cloud-based application monitoring",
            },
            {
              bullet: "Creating personalized parameters and checks",
            },
            {
              bullet: "Setting up personalized alerts",
            },
          ],
        },
        {
          autoId: 7,
          title: "Managed Services",
          list: [
            {
              bullet: "Governance plan for cloud infrastructure",
            },
            {
              bullet:
                "Infrastructure optimization for better performance and cost savings",
            },
            {
              bullet: "Improvement plans",
            },
          ],
        },
        {
          autoId: 8,
          title: "Cloud Infrastructure Management",
          list: [
            {
              bullet:
                "Cloud data center design, development, and implementation",
            },
            {
              bullet: "Operation management",
            },
            {
              bullet: "Cloud infrastructure and application deployment",
            },
          ],
        },
        {
          autoId: 9,
          title: "Cloud Modernization Services",
          list: [
            {
              bullet: "Building an agile, cloud native platform",
            },
            {
              bullet: "Cloud adoption in microservices-based architecture",
            },
            {
              bullet: "Cloud governance and policy",
            },
          ],
        },
      ],
      whyChooseUs: [
        {
          autoId: 1,
          title: "Public Cloud",
          icon: "mdi-lock-open",
          desc:
            "In public cloud, the computing infrastructure is hosted by the cloud vendor at the vendor’s premises.",
        },
        {
          autoId: 2,
          title: "Private Cloud",
          icon: "mdi-cloud-lock",
          desc:
            "The computing infrastructure in private cloud is dedicated to one organization and not shared with others organizations.",
        },
        {
          autoId: 3,
          title: "Hybrid Cloud",
          icon: "mdi-cloud-sync-outline",
          desc:
            "Hybrid cloud refers to a combination of private cloud and public cloud services with orchestration between the two.",
        },
        {
          autoId: 4,
          title: "Disaster Recovery",
          icon: "mdi-cloud-refresh",
          desc:
            "Cloud DR enables business to recover data and implement backup plans through e-records in a cloud environment.",
        },
      ],
      setsApart: [
        {
          autoId: 1,
          text: "World-class expertise",
        },
        {
          autoId: 2,
          text: "Proven use cases",
        },
        {
          autoId: 3,
          text: "Always-on availability",
        },
        {
          autoId: 4,
          text: "Always up-to-date",
        },
        {
          autoId: 5,
          text: "Improved mobility",
        },
        {
          autoId: 6,
          text: "Reduced time-to-market",
        },
        {
          autoId: 7,
          text: "Improved security and compliance",
        },
        {
          autoId: 8,
          text: "Efficient resource utilization",
        },
      ],
      dtaInsights: [
        {
          autoId: 1,
          perc: 84,
          color: "link",
          desc:
            "Percentage of enterprises with multi-cloud strategy as of 2019",
        },
        {
          autoId: 2,
          perc: 61,
          color: "error",
          desc: "Adoption rate of AWS for public cloud services",
        },
        {
          autoId: 3,
          perc: 80,
          color: "orange",
          desc:
            "Of US Small businesses expected to adopt cloud computing by 2020",
        },
        {
          autoId: 4,
          perc: 83,
          color: "pink",
          desc: "Percentage of enterprise workloads to be in cloud by 2020",
        },
        {
          autoId: 5,
          perc: 89,
          color: "teal",
          desc: "Current adoption rate of SaaS across companies of all sizes",
        },
        {
          autoId: 6,
          perc: 21,
          color: "blue",
          desc: "Average improvement in time to market with cloud adoption",
        },
        {
          autoId: 7,
          perc: 64,
          color: "purple",
          desc:
            "Percentage of firms optimizing existing cloud usage for cost savings",
        },
        {
          autoId: 8,
          perc: 39,
          color: "info",
          desc: "Percentage of firms implementing cloud-first strategy",
        },
      ],
    };
  },
};
</script>
