<template>
  <div>
    <v-sheet>
      <v-container py-12>
        <v-row>
          <v-col>
            <h2 class="text-h3 text-lg-h2 mt-0 mt-xl-10">
              Managed IT Services
            </h2>
            <h3 class="text-h4 text-lg-h3 mt-0 mt-xl-10">
              Business Continuity with Managed IT Needs
            </h3>
            <p class="text-body-1 text-lg-h6 font-weight-bold mt-8 mt-xl-10">
              IT industry is growing on high speeds with new technology
              solutions and processes marking their foray every now and then.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              DPI DevOps Services
            </h4>
            <p>
              DPI, with over a decade of proven excellence in IT, has a team
              of experts who can provide you an effective DevOps implementation
              strategy.
            </p>

            <p>
              In this openly-competitive environment, gaining a competitive edge
              has become critical and keeping the business running is the need
              of the hour.
            </p>
            <p>
              So, everything can’t be self-made or self-managed as the focus is
              more on innovation and speed of delivery.
            </p>
            <p>
              This is where the concept of Managed Services emerged, as
              third-party support to IT firms striving to keep their business
              run uninterrupted.
            </p>

            <h4 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              Defining Managed IT Services
            </h4>
            <p>
              Managed Services or Managed IT Services refers to the services
              offered by a third-party i.e., IT Managed Service Provider (MSP),
              who addresses some or all sorts of IT requirements. Managed
              services can happen on-demand, short term or on a contractual
              basis for a long-term duration.
            </p>
            <p>
              In other words, this can also be explained as the process of
              outsourcing a range of IT needs including infrastructure,
              applications, security and maintenance, among others to a
              third-party provider.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              IT Managed Service Providers (MSPs)
            </h4>

            <p>In a broader perspective, IT MSPs can be categorized into:</p>
          </v-col>
          <v-col lg="4" md="6" sm="12">
            <v-alert
              border="bottom"
              colored-border
              color="gray darken-2"
              elevation="8"
              light
            >
              <div class="title gray--text text--darken-3">
                Low Level MSPs
              </div>
              Offer basic IT services such as alerting, monitoring, process
              continuity, with little focus on IT business side and planning.
            </v-alert>
          </v-col>
          <v-col lg="4" md="6" sm="12">
            <v-alert
              border="bottom"
              colored-border
              color="gray darken-2"
              elevation="8"
              light
            >
              <div class="title  gray--text text--darken-3">
                Medium Level MSPs
              </div>
              Beyond process continuity, disaster recovery and maintenance to
              enhancing scalability and further are part of this offering.
            </v-alert>
          </v-col>
          <v-col lg="4" md="6" sm="12">
            <v-alert
              border="bottom"
              colored-border
              color="gray darken-2"
              elevation="8"
              light
            >
              <div class="title  gray--text text--darken-3">
                High Level MSPs
              </div>
              Offer a full set of managed services at their own facility, as a
              combination of both high-level and lower-level services.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4 class="text-h5 text-lg-h4 mt-0 mt-xl-10">
              Types of Managed IT Services
            </h4>
            <h3 class="text-h4 text-lg-h3 mt-0 mt-xl-10">
              In these three different categories, IT MSPs provide a wide range
              of managed service offerings, including:
            </h3>
          </v-col>
        </v-row>
        <v-row
          align="center"
          v-for="item of typesManagedItService"
          :key="item.autoId"
        >
          <v-col class="display-3" cols="auto">
            <v-avatar color="secondary lighten-3" size="72">
              <v-avatar color="secondary" size="54">
                <v-icon size="36" dark>{{ item.icon }}</v-icon>
              </v-avatar>
            </v-avatar>
          </v-col>
          <v-col>
            <v-alert
              border="bottom"
              colored-border
              color="secondary lighten-1"
              elevation="8"
              light
            >
              <div class="title">
                {{ item.title }}
              </div>
              {{ item.desc }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h2 class="text-h5">
              Why Managed IT Services?
            </h2>
            <p class="body-2">
              Increasing dependence on IT assets for improved business
              productivity and the demand for cloud-based managed services make
              Managed IT services need of the hour for most organizations.
            </p>
          </v-col>
          <v-col>
            <v-card height="100%" color="green" dark>
              <v-card-title>USD 282 billion</v-card-title>
              <v-card-text>
                <p class="body-1 font-weight-black">
                  Estimated size of the global Managed IT Services market by
                  2023
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-for="item of devOpsData" :key="item.autoId">
            <v-card height="100%" :color="item.color" dark>
              <v-card-title class="text-h4">
                {{ item.stat }}
              </v-card-title>
              <v-card-text>
                <p class="body-1 font-weight-black">{{ item.desc }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>
              These numbers reiterate the significance of Managed IT Services in
              the current-day IT world.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4 class="text-h4">Business Outcomes</h4>
            <p>
              With these wide range of managed IT services, MSPs promise
              organizations many business-side benefits such as:
            </p>
          </v-col>
          <v-col
            v-for="item of businessOutcomes"
            :key="item.autoId"
            lg="3"
            md="6"
          >
            <v-card flat>
              <v-row>
                <v-col cols="auto">
                  <v-icon color="success">mdi-check</v-icon>
                </v-col>
                <v-col class="body-1">
                  {{ item.text }}
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h6 class="text-h6">
              Choosing a Right IT Managed Service Provider
            </h6>
            <p>
              In a competitive scenario, no organization will want to experiment
              with their business. Moreover, finding the best IT Managed Service
              Provider helps you avoid iterations that are often time consuming
              and cause re-spend.
            </p>
            <p>
              Wait no more and find your strategic MSP to derive the desired
              results in no time.
            </p>
            <p>
              DPI is a US-based strategic IT Managed Services provider with
              more than a decade-long experience in the aforementioned services.
              As one of the top managed service providers based out of Texas, we
              also cater to other US locations such as California, Nevada,
              Texas, Florida, New York, Michigan and more.
            </p>
            <p>
              We have been strategic partners to BFSI, government, healthcare,
              manufacturing, retail and telecom, among other industries, and our
              clients include Fortune 500 companies. Visit our frequently asked
              questions for more information.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Cloud",
  components: {},
  data() {
    return {
      typesManagedItService: [
        {
          autoId: 1,
          title: "Application Management and Support Services (AMS)",
          icon: "mdi-application",
          desc:
            "Managing application portfolio is no more complex. Besides back-office support, AMS promises predictability, improved speed and efficiency, continuous visibility and monitoring. Use the proven methods and enhance your application maturity. AMS offerings include ‘Modernization’, ‘Portfolio Management’, ‘Enterprise Integration’ and ‘Development and Maintenance’.",
        },
        {
          autoId: 2,
          title: "Cloud Managed Services",
          icon: "mdi-cloud",
          desc:
            "MSPs take care of your storage, networks, OS, tools, applications that run on top of the infrastructure. Ultimately, the decision-making over outsourcing and keeping in-house remains with the organization.",
        },
        {
          autoId: 3,
          title: "Managed Security Services",
          icon: "mdi-security",
          desc:
            "Maintaining a robust IT security posture is key to business success today. Challenged with this, Managed Security Services can address this concern by managing core activities security monitoring, threat detection and response, and compliance management.",
        },
        {
          autoId: 4,
          title: "Networks and Infrastructure",
          icon: "mdi-lan",
          desc:
            "Forget the loss of connectivity, MSPs take care of all network tasks, from establishing connections to managing them and preparing the underlying infrastructure for the same.",
        },
      ],
      devOpsData: [
        {
          autoId: 1,
          stat: "64%",
          desc: "Of companies using Managed Services",
          color: "blue",
        },
        {
          autoId: 2,
          stat: "140,000",
          desc: "Avg. number of failing hard drives per week in US IT firms",
          color: "red",
        },
        {
          autoId: 3,
          stat: ">25%",
          desc: "Cost savings on operational expenses with managed services",
          color: "purple",
        },
        {
          autoId: 4,
          stat: "3.3 Billion",
          desc: "Estimated no. of records to fall under cyber threats in 2023",
          color: "teal",
        },
        {
          autoId: 5,
          stat: "60%",
          desc: "Businesses moved away from break-fix model",
          color: "orange",
        },
        {
          autoId: 6,
          stat: "USD 7.9 Billion",
          desc: "Avg. cost of a data breach to a US company",
          color: "pink",
        },
        {
          autoId: 7,
          stat: "32%",
          desc:
            "IT leaders report ‘lack of knowledge’ when it comes to cloud-related challenges",
          color: "yellow darken-2",
        },
      ],
      businessOutcomes: [
        { text: "Core Business Focus" },
        { text: "Enhanced Security Posture" },
        { text: "Technology Expertise" },
        { text: "Operational Efficiency" },
        { text: "Standardized Processes" },
        { text: "Improved Responsiveness" },
        { text: "Reduced IT Costs" },
        { text: "Improved Business Continuity" },
      ],
    };
  },
};
</script>
